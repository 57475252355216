.header {
    width: 100vw;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    color: var(--turquoise);
    padding: 0 5%;
  }
  
  .logo {
    height: 5rem;
    width: auto;
    padding: 2%;
  }
  
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  /* .dropdown {
    padding: .5rem;
    margin: 0;
  }
  
  .dropdown li{
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .navbar ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  
  .navbar li {
    margin-left: 3vw;
  }
  
  .navbar a {
    text-decoration: none;
    font-size: min(1.4rem, 1.4vw);
    font-weight: 650;
  }
  
  .navbar a:hover,
  .navbar a:active,
  .navbar a.active {
    color: black;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    width: 10vw;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 3;
  }
  
  .dropdown-content a {
    float: none;
    font-size: min(1.3rem, 1.3vw);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    color: black;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .search-icon {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--turquoise);
    font-size: 1.5rem;
    padding: 0 3rem 0 3rem;
  }
  .search-icon:hover {
    color: var(--blue);
  }
  
  .search-dropdown {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50.5vw;
    height: 9rem;
    top: 6rem;
    right: 3.5rem;
    background-color: white;
    z-index: 2;
  }
  
  .searchTitle1,
  .searchTitle2 {
    font-size: 1.18rem;
    padding-right: 3rem;
    width: 21rem;
  }
  
  .searchTitle1 {
    color: var(--turquoise);
  }
  
  .searchTitle2 {
    color: white;
  }
  
  .search-option-1,
  .search-option-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .search-option-1 {
    width: 50.5vw;
  }
  
  .search-option-2 {
    background-color: var(--turquoise);
    width: 50.5vw;
  }
  
  .search-dropdown input[type="text"] {
    border: none;
    width: 60%;
    padding: 0.75rem;
    font-size: 1.25rem;
    box-sizing: border-box;
    height: 2.5rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  }
  
  .search-dropdown button[type="submit"] {
    background-color: var(--turquoise);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .search-dropdown button[type="submit"]:hover {
    background-color: var(--light-turquoise);
  }
  
  /* For mobile phones: */
  @media only screen and (max-width: 480px) {
    .header {
      text-align: center;
      padding-right: 1rem;
      height: 100%;
      background-color: black;
    }
  
    .navbar {
      padding: 0;
      margin: 0;
    }
  
    .navbar a {
      font-size: 0.455rem;
    }
  
    .navbar li {
      margin-left: 2vw;
    }
  
    .dropdown-content {
      background-color: black;
    }
  
    .navbar a:hover {
      color: var(--blue);
    }
  
    .logo {
      height: 1.75rem;
      width: auto;
      padding: 2%;
    }
  
    .nav-sub-header {
      font-family: "Montserrat";
      font-size: 0.927rem;
      color: #2c93eb;
    }
  
    .search-icon {
      color: var(--turquoise);
      font-size: 0.55rem;
      padding: 0.45rem 0.5rem .25rem 0.75rem;
      margin: 0;
    }
  
    .search-dropdown {
      width: 75vw;
      height: 6rem;
      top: 1.5rem;
      right: 1.5rem;
      z-index: 2;
    }
    
    .searchTitle1,
    .searchTitle2 {
      font-size: .75rem;
      padding-right: .5rem;
      width: 15rem;
    }
    
    .searchTitle1 {
      color: var(--turquoise);
    }
    
    .searchTitle2 {
      color: white;
    }
    
    .search-option-1,
    .search-option-2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: .5rem;
    }
    
    .search-option-1 {
      width: 75vw;
    }
    
    .search-option-2 {
      background-color: var(--turquoise);
      width: 75vw;
    }
    
    .search-dropdown input[type="text"] {
      border: none;
      width: 100%;
      padding: 0.25rem;
      font-size: .75rem;
      box-sizing: border-box;
      height: 2rem;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
    }
    
    .search-dropdown button[type="submit"] {
      background-color: var(--turquoise);
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0.45rem;
      cursor: pointer;
      font-size: 1rem;
    }
    
    .search-dropdown button[type="submit"]:hover {
      background-color: var(--light-turquoise);
    }
  }
  