.footer-container {
    width: 100vw;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    color: var(--dark-blue);
    flex-shrink: 0;
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  
  .logo {
    height: 3rem;
    width: auto;
  }
  
  .socials-container {
    margin: 2rem;
  }
  
  .igIcon {
    font-size: 2rem;
  }
  
  .fbIcon {
    font-size: 2rem;
    padding-left: 1.5rem;
  }
  
  @media only screen and (max-width: 480px) {
    .footer-container {
      width: 100vw;
      height: 2.75rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background-color: var(--dark-blue);
      color: white;
      flex-shrink: 0;
      margin: 0.5rem 0.5rem 0 0.5rem;
      padding: 0.5rem 0.5rem 0 0.5rem;
    }
  
    .logo {
      height: 2rem;
    }
  
    .socials-container {
      margin: 1rem;
    }
  
    .igIcon,
    .fbIcon {
      font-size: 1rem;
      padding-left: 1rem;
    }
  }
  