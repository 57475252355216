.header {
  background: white;
}

.grids-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.content-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  grid-gap: 0px 4rem;
  /* gap: 4rem; */
}

@media only screen and (max-width: 480px) {
  .content-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
  }
}