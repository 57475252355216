
:root {
    --white: #ffffff;
    --light-turquoise: rgb(30, 220, 241);
    --turquoise: #0ca6b3;
    --dark-turquoise: #056a72;
    --light-blue: hsl(209, 74%, 60%);
    --blue: #0981c7;
    --dark-blue: #113458;
  }
  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  html,
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    color: white;
    background-color: var(--dark-blue);
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  .main{
    flex:1;
  }