@media only screen and (max-width: 480px) {

    .pdf {
        /* width: 100%; */
        /* border: 1px solid #ccc;
        border-radius: 4px; */
      }
      
      /* Additional styles for specific elements within the embedded PDF */
      .pdf embed {
        width: 95vw;
        height: 70vh;
        background-color: #f1f1f1;
      }

}