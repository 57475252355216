.footer-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .footer-nav-link {
    margin: 1.5rem;
    margin-left: 1rem;
  }
  
  @media only screen and (max-width: 480px) {
    .footer-nav-link {
      font-size: 0.5rem;
      margin: .5rem;
    }
  }
  