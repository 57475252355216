.ticker-container {
    max-width: 100vw;
    height: 6rem;
    background-color: black;
    font-size: 1.25rem;
  }
  
  .ticker-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding-left: 0.75rem;
    color: white;
    font-size: 1.55rem;
  }
  
  .ticker-header1 {
    color: white;
    margin-right: 3rem;
  }
  
  .ticker-header2 {
    /* padding-left: 18rem; */
    color: gold;
  }
  
  .ticker-row1 {
    color: var(--blue);
    height: 1.75rem;
    padding-left: 0.75rem;
  }
  
  .ticker-row2 {
    color: var(--turquoise);
    height: 1.75rem;
    padding-left: 0.75rem;
  }
  
  @media only screen and (max-width: 480px) {
    .ticker-container {
      height: 4rem;
    }
  
    .ticker-header {
      font-size: .625rem;
      /* padding-top: 0.1rem; */
      height: 1.25rem;
      padding: .5rem;
    }
  
    .ticker-header1 {
      margin:0;
    }
  
    .ticker-header2 {
      padding-left: .5rem !important;
    }
  
    .ticker-row1,
    .ticker-row2 {
      height: 1.15rem;
      padding-left: 0.25rem;
      font-size: 0.70rem;
    }
  }
  