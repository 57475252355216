.home-img-container {
    position: relative;
    max-width: 100%;
    height: auto;
  }
  
  .home-img {
    max-width: 100%;
    height: auto;
    /* filter: grayscale(75%) brightness(0.8); */
  }
  
  .pledge-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 155px;
    width: 145px;
    font-size: 1.05rem;
    top: 72%;
    left: 83%;
    background-color: #3d97ed;
    border-radius: 8px;
  }
  
  .pledge-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(45deg, hsl(207, 64%, 15%), hsl(189, 91%, 34%)); */
    color: white;
    /* padding: 5rem; */
    height: 100px;
    width: 100px;
    border-radius: 50%;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    /* box-shadow: 8px 2px 18px 12px rgba(0, 0, 0, 0.5); */
    /* border: 4px solid hsl(207, 64%, 28%); */
  }
  
  .pledgeIcon {
    width: 100%;
    height: 100%;
    padding: 0;
    /* margin-right: 0.5rem; */
    /* filter: invert(1); */
    border-radius: 6px;
  }
  
  .pledge-button-header {
    text-align: center;
    width: 80%;
    color: white;
    padding: 0.25rem;
    font-weight: 500;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .textBox {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    top: 42.75%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--white);
    border-radius: 5px;
    padding: 2%;
  }
  
  .textBox h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1%;
    font-size: 7vw;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.5px);
    width: 75vw;
  }
  .textBox h2 {
    font-size: 1.75vw;
    padding: 0.5rem;
  
    color: rgba(12, 166, 179);
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); */
  }
  
  .textBox p {
    font-size: 1.5vw;
  }
  
  .bigger {
    font-size: 1.55vw !important;
    color: var(--turquoise);
    text-shadow: 4px 4px 7px rgba(0, 0, 0, 0.9);
  }
  
  .search-icon {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding-left: 0;
    color: var(--turquoise);
    font-size: 1.5rem;
  }
  .search-icon:hover {
    color: black;
  }
  
  .search-dropdown {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 9rem;
    top: 14rem;
    right: 45rem;
    z-index: 2;
  }
  
  .icon1{
    color: var(--turquoise);
    padding: .25rem;
    margin: 0;
  }
  
  .icon2{
    color: var(--white);
    padding: .25rem;
    margin: 0;
  }
  
  /* .icon1:hover{
    color: white;
    background-color: var(--turquoise);
  }
  
  .icon2:hover{
    color: var(--turquoise);
    background-color: white;
  } */
  
  .searchTitle1,
  .searchTitle2 {
    font-size: 1.19rem;
  }
  
  .searchTitle1 {
    color: var(--turquoise) !important;
  }
  
  .searchTitle2 {
    color: white !important;
  
  }
  
  .search-option-1,
  .search-option-2 {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }
  
  .search-option-1 {
    position: absolute;
    top: 14rem;
    right: 14.5rem;
    width: 19vw;
    background-color: white;
  }
  
  .search-option-2 {
    position: absolute;
    top: 20rem;
    right: 39rem;
    background-color: var(--turquoise);
    width: 16vw;
    padding: .25rem;
  }
  
  .search-dropdown input[type="text"] {
    border: none;
    width: 60%;
    /* padding: 0.75rem; */
    font-size: 1.25rem;
    box-sizing: border-box;
    height: 2.5rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  }
  
  .search-dropdown button[type="submit"] {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 2.5rem;
    margin-left: 3rem;
  }
  
  /* .search-dropdown button[type="submit"]:hover {
    background-color: var(--light-turquoise);
  } */
  
  /* For mobile phones: */
  @media only screen and (max-width: 480px) {
    .home-img {
      max-width: 100%;
      height: 150px;
    }
  
    .textBox {
      top: 17.5%;
      left: 50%;
    }
  
    .textBox h2 {
      font-size: 3vw;
      padding-top: 0.25rem;
      /* border-bottom: 2px solid rgb(255, 255, 255, 0.25); */
    }
  
    .textBox p,
    .bigger {
      padding: 0;
      font-size: 2.5vw !important;
    }
  
    .pledge-button-container {
      height: 65px;
      width: 60px;
      top: 11rem;
      left: 21.5rem;
    }
  
    .pledge-button {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
      /* border: 1px solid hsl(207, 64%, 28%); */
    }
  
    .pledge-button-header {
      font-size: 0.4rem;
      font-weight: 400;
      width: 100%;
    }
  
    .search-dropdown {
      top: 0rem;
      right: 0rem;
    }
  
    .searchTitle1,
    .searchTitle2 {
      font-size: 0.450rem !important;
    }
  
    .searchTitle2 {
      width: 100%;
    }
  
    .search-option-1 {
      position: absolute;
      top: 8.35rem;
      right: 14.75rem;
      width: 30vw;
    }
  
    .search-option-2 {
      position: absolute;
      top: 7rem;
      right: 19.5rem;
      width: 22vw;
      padding: 0;
    }
  
    .search-dropdown button[type="submit"] {
      border-radius: 4px;
      font-size: .5rem;
      margin: 0;
    }
  
    .icon1{
      margin-left: rem;
    }
  }
  