.container {
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--dark-blue);
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
  }
  
  .content-container-header {
    margin: 3.5rem 0 0 0;
    font-size: 3.5rem;
    font-weight: 700;
    text-shadow: 0.5px 0.5px 1px #000000;
    text-align: center;
  }
  
  .container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 55%;
  }
  
  .header {
    margin: 2rem 0 1rem 0;
    font-size: 2rem;
  }
  
  .content {
    margin: 1rem 0 2rem 0;
    font-size: 1.25rem;
  }
  
  .second-pic {
    margin-top: 7rem;
    width: 50%;
    height: auto;
  }
  
  .strong {
    font-weight: bold;
    color: rgb(205, 27, 27) !important;
  }
  
  @media only screen and (max-width: 480px) {
    .content-container-header {
      margin: 1.5rem 0 0 0;
      padding: 0 1rem 0 1rem;
      font-size: 2.05rem;
      font-weight: 500;
      text-shadow: 0.5px 0.5px 1px #000000;
    }
  
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      margin: 0;
    }
  
    .second-pic {
      margin-top: 3rem;
      width: 90%;
      height: auto;
    }
  
    .container2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      width: 85%;
    }
  
    .header {
      margin: 0.5rem 0 0.5rem 0;
      padding: 0;
      font-weight: 600;
      font-size: 1rem;
    }
  
    .content {
      margin: 0.5rem 0 0.5rem 0;
      padding: 0;
      font-size: 0.85rem;
    }
  
    /* .header1 {
      padding: 0.25rem;
      font-size: 1rem;
      text-align: center;
      text-shadow: 0.05px 0.05px 0.25px #000000;
    }
  
    .content1 {
      padding: 0.25rem;
      font-size: 1rem;
      line-height: 1.85rem;
    } */
    
    .list {
      font-size: 0.825rem;
    }
  }
  