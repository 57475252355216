.fp-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* padding-top: 7%; */
  }
  
  .fp-container-header {
    position: absolute;
    font-family: Georgia, "Times New Roman", Times, serif;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    text-align: center;
    background-color: rgb(255, 255, 255, .9);
    color: var(--dark-blue);
    padding: 2%;
    border-radius: 4px;
    box-shadow: 8px 8px 18px 12px rgba(0, 0, 0, 0.25);
    text-shadow: .25px .25px 1px #000000;
  }
  
  .fp-container h1 {
    font-size: 3.5vw;
  }
  
  .fp-container h2 {
    font-size: 2rem;
    padding: 1rem;
  }
  
  .fp-container h3 {
    font-size: 1.5rem;
    padding: 1rem;
  }
  
  .middle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /* text-shadow: 1px 1px 8px #000000; */
  }
  
  .italics {
    font-style: italic;
  }
  
  .middle-container-options h2 {
    font-size: 1.5rem;
    
  }
  
  .fp-content-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    background-color: white;
    color: var(--dark-blue);
    padding: 5rem;
  }
  
  .fp-content-header {
    font-size: 3rem !important;
  }
  
  .fp-content-text {
    font-size: 1.25rem;
  }
  
  .dvw-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  }
  
  .dvw-icon{
    height: 50px;
    width: 50px;
    margin: .5rem;
    filter: invert(1);
  }
  .shop-links-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    width: 85%;
  }
  
  .shop-links{
    font-size: 1.15rem;
  }
  
  .search1, .search2{
    display: flex;
    flex-direction: row;
    background-color: var(--turquoise);
    margin: 0 .5rem 0 .5rem;
    border-radius: 4px;
    padding: .5rem;
  }
  
  .search2{
    background-color: white;
    color: var(--turquoise);
    margin-top: .5rem;
  }
  
  .icon{
    /* color: white; */
    font-size: 1.25rem;
    margin-left: .75rem;
  }
  
  @media only screen and (max-width: 480px) {
    .fp-container {
      width: 100vw;
    }
  
    .fp-container-header{
      text-shadow: .05px .05px .05px #000000;
      padding: 0;
    }
  
    .middle-container {
      padding: 0;
      margin: 0;
    }
  
    .middle-container-header {
      font-size: 1.25rem !important;
      padding: 0;
      margin: 0;
    }
  
    .italics {
      /* padding-top: .25 !important; */
      margin-top: 0 !important;
    }
  
    .middle-container-header2 {
      width: 100%;
      text-align: center;
      margin:0;
    }
  
    .middle-container h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.05rem !important;
      padding: 0;
      margin: 0;
    }
  
    .middle-container h3 {
      font-size: 0.75rem !important;
      margin: 0;
      padding: 0;
    }
  
    .middle-container-options h2 {
      font-size: 0.5rem !important;
    }
  
    .dvw-icon{
      height: 20px;
      width: 20px;
      color: white;
      margin: .5rem;
    }
  }
  