.content-button-container {
  padding: 4rem 0 5rem 0;
  width: 100%;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 8px 2px 18px 6px rgba(0, 0, 0, 0.5);
  height: 17rem;
  width: 20rem;
  background: linear-gradient(45deg, hsl(208, 79%, 35%), hsl(186, 68%, 58%));
  border-radius: 8px;
  padding: 2rem;
  filter: hue-rotate(0deg);
}

.icon-container:hover {
  filter: hue-rotate(20deg);
}

.icon {
  padding: 3rem 1.5rem 0 1.5rem;
  font-size: 12rem;
  /* color: linear-gradient(45deg, hsl(208, 79%, 35%), hsl(186, 68%, 58%)); */
  height: 10rem;
  width: 10rem;
  color: white;
  margin-top: 12px;
}

.icon:hover {
  color: #113458;
}

.action-title {
  display: flex;
  justify-content: center;
  font-size: 28px;
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 1.25rem;
  z-index: 2;
  color: white;
  padding-bottom: 1rem;
  text-shadow: .5px .5px 3px #000000;
}

.background-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 150px;
  /* background: linear-gradient(45deg, hsl(208, 79%, 20%), hsl(186, 48%, 78%)); */
  border: 2px solid #ccc;
  box-shadow: 8px 8px 18px 12px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  margin: 0 4rem 0 4rem;
  border-radius: 8px;
}

.backup-text {
  width: 75%;
  font-size: 1.45vw;
}

.background-container:hover {
  background: white;
  color: #0f3c56;
}

.icon-title {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  text-shadow: 1px 1px 2px #000000;
  width: 20rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 400;
  font-size: 1.5rem;
  padding: 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

/* For mobile phones: */
@media only screen and (max-width: 480px) {
  .content-button-container {
    margin: 0 0 0.5rem 0;
    padding: 0;
  }

  .icon-container {
    box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.5);
    height: 10.5rem;
    width: 29.25vw;
    margin: 1rem 0 1rem 0;
  }

  .icon {
    width: 8rem;
    padding-top: 3.5rem;
    filter: invert(1);
  }

  .icon-title {
    width: 29.25vw;
    font-size: 1rem;
    padding: 0.5rem 0 0.5rem 0;
  }

  .action-title {
    font-size: 2.25vw;
    font-weight: 900 !important;
    margin-bottom: 0.5rem;
  }

  .background-container {
    width: 20vw;
    height: 5vh;
    font-size: 0.25rem;
    box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
  }

  .backup-text {
    font-size: 0.5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}