.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--dark-blue);
    width: 70%;
    padding: 3rem;
    margin-top: 5rem;
    border-radius: 8px;
    box-shadow: 8px 8px 18px 12px rgba(0, 0, 0, 0.25);
  }
  
  .link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .content-container {
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
  
  .header {
    padding: 0.25rem;
    font-size: 2rem;
    text-align: center;
    text-shadow: 0.05px 0.05px 0.25px #000000;
  }
  
  .content {
    padding: 0.25rem;
    font-size: 1.5rem;
    line-height: 1.85rem;
    text-align: center;
  }
  
  .learn-more-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .learn-more-button {
    background-color: var(--dark-blue);
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
    margin-top: 1rem;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  }
  
  .learn-more-button:hover {
    background-color: var(--turquoise);
    color: var(--dark-blue);
  }
  
  .pic {
    width: 60%;
    height: auto;
    box-shadow: 8px 8px 18px 12px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
  
  .strong {
    font-weight: bold;
    color: rgb(205, 27, 27) !important;
  }
  
  @media only screen and (max-width: 480px) {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 85%;
      padding: .5rem;
      border-radius: 8px;
      box-shadow: 4px 4px 9px 6px rgba(0, 0, 0, 0.25);
      margin-top: 2rem;
    }
  
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      width: 100%;
      padding: 0.5rem;
    }
  
    .link-container {
      width: 70%;
    }
  
    .header {
      font-size: 1.5rem;
      text-shadow: 0.05px 0.05px 0.25px #000000;
    }
  
    .content {
      padding: 0.25rem;
      font-size: .9rem;
      line-height: 1.85rem;
    }
  
    .pic {
      width: 80%;
      box-shadow: 4px 4px 9px 6px rgba(0, 0, 0, 0.25);
    }
  }
  